/* Base styles */
body {
  background: #151515;
  color: white;
  min-height: 100vh;
  box-sizing: border-box;
}

/* Main container styles */
.app-container {
  background: #151515;
  border-radius: 24px;
  overflow: hidden;
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
  min-height: calc(100vh - 40px);
  /*box-shadow: 0 0 40px rgba(0, 0, 0, 0.3);*/
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Navbar Styles */
.navbar {
  background: #1A1A1A;
  color: white;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
}

.navbar-brand h1 {
  color: white;
  margin: 0;
}

.navbar-brand img {
  max-height: 40px; /* Adjust height as needed */
  width: auto; /* Maintain aspect ratio */
}


.navbar-buttons {
  display: flex;
  gap: 2rem;  /* Increased gap between buttons */
}

.navbar-links {
  display: flex;
  gap: 2rem;
}

.navbar-links a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
}

/* New navbar button styles */
.navbar .demo-button {
  background: transparent;
  border: none;
  color: white;
  padding: 0.5rem 0;  /* Reduced vertical padding */
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;  /* For underline positioning */
}

/* Underline hover effect */
.navbar .demo-button::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #1976D2;
  transition: width 0.3s ease;
}

.navbar .demo-button:hover::after {
  width: 100%;
}

/* Keep the original demo-button style for other buttons */
.demo-hero-button {
  background: #1976D2;
  color: white;
  padding: 0.75rem 1.75rem;
  font-size: 1.1rem;
  font-weight: 600;
  border: 0px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.demo-hero-button:hover {
  /*background: #0c64bd;*/
  transform: translateY(-2px);
}

/* Hero Section */
.hero-section {
  padding: 2rem 6rem;
  margin-top: 1rem;
  display: flex;
  align-items: left;
  justify-content: left;
  background: transparent;
  position: relative;
  min-height: 70vh;
  gap: 4rem;
}

.hero-section .hero-avatar {
  margin-bottom: 15rem;
}

.hero-content {
  flex: 1;
  max-width: 600px;
  margin-right: 14rem;
  margin-left: 0rem;
  /*margin: 0 auto;*/
}

.hero-content h1 {
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.1;
  margin-top: 0rem;
  margin-bottom: 1.5rem;
  color: white;
  text-align: left;
}

.hero-container {
  margin-bottom: 1rem;
}

.hero-content p {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #CCCCCC;
  margin-bottom: 2rem;
  max-width: 900px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.hero-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.hero-message {
  font-size: 1.5rem;
  font-weight: 500;
  color: #1C84D5; /* Light blue text */
  margin-bottom: 0.5rem;
  text-align: left;
}

.cursor {
  display: inline-block;
  width: 5px;
  background: #1C84D5;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.demo-button {
  background: transparent;
  color: #1976D2;
  padding: 0.75rem 1.75rem;
  font-size: 1.1rem;
  font-weight: 550;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}


.hero-avatar {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0;
}

.hero-avatar img {
  width: 20rem;
  height: auto;
  object-fit: contain;
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media (max-width: 1200px) {
  .hero-section {
    padding: 2rem 4rem;
    gap: 2rem;
  }
  
  .hero-content h1 {
    font-size: 3.5rem;
  }
  
  .hero-avatar img {
    width: 18rem;
  }
}

@media (max-width: 768px) {
  .hero-section {
    padding: 2rem;
    flex-direction: column;
    gap: 1rem;
  }

  .hero-content h1 {
    font-size: 2.5rem;
  }

  .hero-avatar img {
    width: 14rem;
  }
}

/* Features Section */
.features {
  background: transparent;
  padding: 4rem 2rem;
}

.features h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: white;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  max-width: 1200px;
  margin: 0 auto;
}

.feature-card {
  background: #262626;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  min-width: 0;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-image {
  width: 100%;
  height: 180px;
  overflow: hidden;
}

.feature-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.feature-content {
  padding: 1.25rem;
  background: #262626;
}

.feature-content h3 {
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
  color: white;
}

.feature-content p {
  color: #999;
  line-height: 1.5;
  font-size: 0.95rem;
  margin: 0;
}

@media (max-width: 1200px) {
  .features-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .features-grid {
    grid-template-columns: 1fr;
  }
  
  .feature-card {
    max-width: 400px;
    margin: 0 auto;
  }
}

/* Demo Section */
.demo-section {
  padding: 3rem 2rem;
  background: transparent;
}

.demo-header {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 2rem;
}

.demo-header .gradient-text {
  padding-left: 1rem;
  background: linear-gradient(to right, #40F8FF, #9580FF);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.demo-header h2 {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.demo-header p {
  font-size: 1.2rem;
  line-height: 1.5;
  opacity: 0.9;
}

.demo-form {
  max-width: 1200px;
  display: block;
  margin: 0 auto;
  background: transparent;
  border-radius: 12px;
  padding: 2rem 4rem;
}

.form-field {
  margin-bottom: 1.5rem;
  margin-right: 1rem;
  flex: 1;
  min-width: 0;
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: 1.5rem;
}

.form-field label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;
}

.form-field input {
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  border: none;
  font-size: 1rem;
}

.form-field input::placeholder {
  color: #999;
}

.demo-submit {
  width: auto;
  padding: 0.75rem 2rem;
  margin: 1rem auto 0;
  display: block;
}

@media (max-width: 768px) {
  .demo-form {
    padding: 2rem;
  }
  
  .form-row {
    flex-direction: column;
    gap: 1rem;
  }
}

/* FAQ Section */
.faq {
  background: transparent;
  color: white;
}

.faq h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: white;
  padding-left: 0;
}

.faq-list {
  max-width: 1200px;
  margin: 0 auto;
}

.faq-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.faq-question {
  width: 100%;
  text-align: left;
  padding: 1.5rem 0;
  background: none;
  border: none;
  font-size: 1.25rem;
  font-weight: 500;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: color 0.3s ease;
}

.faq-question:hover {
  color: #0056b3;
}

.faq-icon {
  font-size: 1.5rem;
  font-weight: 300;
  color: #666;
}

.faq-answer {
  padding: 0 0 1.5rem;
  color: #999;
  line-height: 1.6;
  font-size: 1.1rem;
}

.faq-answer p {
  margin-bottom: 1rem;
}

.faq-answer p:last-child {
  margin-bottom: 0;
}

/* Footer */
.footer {
  background: transparent;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  padding: 4rem 2rem 2rem;
}

.footer-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}

.footer-section h4 {
  margin-bottom: 1rem;
}

.footer-section a {
  display: block;
  color: #999;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-bottom {
  text-align: center;
  padding-top: 2rem;
  border-top: 1px solid #333;
}

/* Trusted By Styles */
.trusted-by {
  padding: 4rem 2rem;
  background: white;
  text-align: center;
}

.logo-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.logo-grid img {
  height: 40px;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.logo-grid img:hover {
  opacity: 1;
}

.scroll-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  background: rgba(25, 118, 210, 0.9);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 1000;
  backdrop-filter: blur(5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.scroll-to-top:hover {
  background: rgba(25, 118, 210, 1);
  transform: translateY(-3px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .scroll-to-top {
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
}

/* Video section styles */
.video-container {
  background: transparent;
}

.video-header {
  text-align: center;
}

.video-header h2 {
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.2;
  color: white;
}

.gradient-text {
  padding-left: 1rem;
  background: linear-gradient(to right, #40F8FF, #9580FF);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
}

/* Keep your existing video-section styles */
.video-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  min-height: 400px;
  margin: 0 auto;
}

.video-section video {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .video-header {
    padding: 0 2rem;
  }

  .video-header h2 {
    font-size: 2.5rem;
  }
}

/* Animation styles */
.fade-in-section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1.5s ease-out, transform 1.5s ease-out;
  will-change: opacity, transform;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: translateY(0);
}

/* Add different delays for children */
.fade-in-section .feature-card:nth-child(1) { transition-delay: 0.1s; }
.fade-in-section .feature-card:nth-child(2) { transition-delay: 0.2s; }
.fade-in-section .feature-card:nth-child(3) { transition-delay: 0.3s; }
.fade-in-section .feature-card:nth-child(4) { transition-delay: 0.4s; }
